import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[nodaNewYearTheme]',
  standalone: true,
  exportAs: 'newYearThemeDirective',
})
export class NewYearThemeDirective {
  private readonly HAS_THEME: boolean = true;

  @HostBinding('attr.new-year-theme')
  public get isNewYearTheme(): boolean {
    const today: Date = new Date();

    const month = today.getMonth() + 1;
    const day = today.getDate();

    return (month === 12 && day >= 15) || (month === 1 && day <= 15);
  }

  @HostBinding('class.new-year-theme')
  public get hasNewYearTheme(): boolean {
    return this.HAS_THEME;
  }
}
