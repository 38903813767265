import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';
import { navLogoComponentImports } from '@layout/nav/logo/nav-logo.imports';
import { NODA_WEB_LINK } from '@shared/constants/noda-links';

@Component({
  selector: 'noda-nav-logo',
  standalone: true,
  templateUrl: './nav-logo.component.html',
  styleUrls: ['./nav-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: navLogoComponentImports,
})
export class NavLogoComponent {
  protected readonly NODA_WEB_LINK: string = NODA_WEB_LINK;
  public targetMethod: InputSignal<string> = input('_self');
}
