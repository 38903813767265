@if (targetMethod() | equal : '_self') {
<a
  aria-label="Logo"
  class="logo"
  [queryParamsHandling]="'merge'"
  [routerLink]="'/' | addLangPrefix | async"
>
  <ng-container [ngTemplateOutlet]="icon" />
</a>

} @else {
<a
  aria-label="Logo"
  class="logo"
  [href]="NODA_WEB_LINK"
  [target]="targetMethod"
>
  <ng-container [ngTemplateOutlet]="icon" />
</a>

}

<ng-template #icon>
  <svg-icon
    name="header/logo.svg#logo"
    class="icon"
  />
  <div
    #newYearTheme="newYearThemeDirective"
    nodaNewYearTheme
  >
    <img
      *ngIf="newYearTheme.isNewYearTheme"
      alt="new-year-ball"
      class="new-year-ball"
      [src]="'new-year-theme/ball-header.svg' | src : 'assets'"
    />
  </div>
</ng-template>
